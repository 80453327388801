<template>
  <FullHeightSdCardWrapper>
    <sdPageHeader title="Payment & Shipping Method" />
    <a-row justify="center" :gutter="25">
      <a-col :md="12" :xs="24">
        <Suspense>
          <template #default>
            <sdCards v-if="order.payment" title="Payment Information">
              <p :style="{ marginTop: '15px' }">{{ paymentInformation }}</p>
              <p>The order was placed using {{ order.order_currency_code }}</p>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
      <a-col :md="12" :xs="24">
        <Suspense>
          <template #default>
            <sdCards title="Shipping Information">
              <p :style="{ marginTop: '15px' }">
                <b>{{ order.shipping_description }}</b>
              </p>
              <p>
                Total Shipping Charges: {{ currencyFromCode(order.order_currency_code) }} {{ order.shipping_amount }}
              </p>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
      <a-col :md="24" :xs="24" class="mt-25" v-show="isAvailable">
        <Suspense>
          <template #default>
            <sdCards title="Appointment">
              <template #button>
                <sdButton class="btn-signin" type="warning" @click="newAppointment">Attach New Appointment</sdButton>
              </template>
              <TableWrapper class="table-responsive" v-if="true">
                <a-table
                  :dataSource="tableData"
                  :columns="tableColumns"
                  :loading="isLoading"
                  :pagination="{
                    current: pagination.currentPage,
                    defaultPageSize: pagination.perPage,
                    total: pagination.total,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  }"
                  @change="handleTableChange"
                />
              </TableWrapper>
              <div v-else>
                <p :style="{ marginTop: '15px' }">No Data</p>
              </div>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
      <a-col :md="12" :xs="24"></a-col>
    </a-row>
    <AppointmentModal v-if="visible" />
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { FullHeightSdCardWrapper } from '@/view/styled';
import { currencyFromCode } from '@/utility/filter';
import AppointmentModal from '../../../../appointments/overview/AppointmentModal.vue';
import { TableWrapper } from '@/view/styled';

const tableColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Start Time',
    dataIndex: 'start_time',
    key: 'start_time',
  },
  {
    title: 'End Time',
    dataIndex: 'end_time',
    key: 'end_time',
  },
  {
    title: 'Technician ID',
    dataIndex: 'technician_id',
    key: 'technician_id',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

export default {
  components: {
    FullHeightSdCardWrapper,
    AppointmentModal,
    TableWrapper,
  },
  setup() {
    const { state, dispatch, commit } = useStore();
    const order = computed(() => state.orders.order);
    const appointment = computed(() => state.appointments.appointment);
    const visible = computed(() => state.omni.showModal);
    const isAvailable = computed(() => {
      let scopes = state.auth.user.scopes;
      return scopes?.some((scope) => scope.name === 'appointments');
    });

    const pagination = computed(() => state.appointments.pagination);
    const handleTableChange = async (p) => {
      commit('updatePagination', { key: 'currentPage', value: p.current });
      commit('setHasOrder', true);
      await dispatch('fetchAppointments');
    };
    const isLoading = computed(() => state.appointments.loading);

    const appointments = computed(() => state.appointments.appointmentsData);
    console.log(appointments, 'appointments');
    const tableData = computed(() => {
      if (appointments?.value === undefined) return [];
      return appointments.value.map((appointment) => {
        const {
          id,
          start_time,
          end_time,
          // order_id,
          // booked_online,
          // slot_id,
          // note,
          // internal_booking,
          technician,
          // customer,
        } = appointment;

        // const to = {
        //   name: 'omni-appointments-edit',
        //   params: { id },
        // };

        let date = start_time?.slice(0, 11);
        // const labelColors = {
        //   open: 'magenta',
        //   pending: 'gold',
        //   completed: 'cyan',
        // };
        // let technician = technicianData.value?.filter(technician => technician.id === technician_id);
        // technician = technician?.length > 0 ? technician[0]?.name : technician?.name;

        return {
          date,
          start_time: start_time && start_time.slice(11, 16),
          end_time: end_time && end_time?.slice(11, 16),
          // customer,
          // order_id,
          // booked_online,
          // slot_id,
          // note,
          // internal_booking: internal_booking ? 'Yes' : 'No',
          technician_id: technician?.name,
          action: (
            <div class="table-actions">
              <>
                <sdButton
                  class="btn-icon"
                  type="default"
                  shape="circle"
                  onClick={async () => {
                    await dispatch('fetchAppointmentData', id);
                    commit('setIsEdit', true);
                    commit('setHasOrder', true);
                    commit('setShowModal', true);
                  }}
                >
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>

                <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteAppointment', id)}>
                  <sdButton class="btn-icon" type="default" shape="circle">
                    <sdFeatherIcons type="trash" size="16" />
                  </sdButton>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      });
    });

    const paymentInformation = computed(() => {
      // let result = {};
      // const infoKeys = ['paymmentID', 'paymentID2', 'method_title'];

      // state.orders.order?.extension_attributes?.payment_additional_info.map(({ key, value }) => {
      //   if (infoKeys.includes(key)) {
      //     result[key] = value;
      //   }
      // });

      // return `${result['method_title']}-${result['paymmentID']}-${result['paymentID2']}`;
      return state.orders.order?.extension_attributes?.payment_additional_info?.map((info) => info?.value).join(', ');
    });

    const editAppointment = () => {
      // await dispatch('fetchAppointmentData', params.id);
      commit('setIsEdit', true);
      commit('setShowModal', true);
    };

    const newAppointment = async () => {
      await commit('initAppointmentData');
      await commit('setAppointmentOrder', order.value.entity_id);
      await commit('setAppointmentCustomer', order.value.customer_firstname + ' ' + order.value.customer_lastname);
      commit('setIsEdit', false);
      commit('setHasOrder', true);
      commit('setShowModal', true);
    };

    onMounted(async () => {
      commit('setHasOrder', true);
    });

    return {
      order,
      currencyFromCode,
      paymentInformation,
      appointment,
      editAppointment,
      visible,
      newAppointment,
      isAvailable,
      tableData,
      pagination,
      handleTableChange,
      isLoading,
      tableColumns,
    };
  },
};
</script>
