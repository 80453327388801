<template>
  <FullHeightSdCardWrapper>
    <ItemsToShipWrapper>
      <sdPageHeader title="Items to Fulfilment" />
      <a-row justify="center" :gutter="25">
        <a-col :xs="24">
          <sdCards>
            <TableWrapper class="table-responsive">
              <a-table :dataSource="tableData" :columns="tableColumns" :pagination="{ defaultPageSize: 5 }" />
            </TableWrapper>
          </sdCards>
        </a-col>
      </a-row>
    </ItemsToShipWrapper>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { ItemsToShipWrapper } from './style';
import { FullHeightSdCardWrapper, TableWrapper } from '@/view/styled';

const tableColumns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Qty to Invoice',
    dataIndex: 'qty_to_invoice',
    key: 'qty_to_invoice',
  },
  {
    title: 'Subtotal',
    dataIndex: 'sub_total',
    key: 'sub_total',
  },
  {
    title: 'Tax Amount',
    dataIndex: 'tax_amount',
    key: 'tax_amount',
  },
  {
    title: 'Discount Amount',
    dataIndex: 'discount_amount',
    key: 'discount_amount',
  },
  {
    title: 'Row total',
    dataIndex: 'row_total',
    key: 'row_total',
  },
];

export default {
  name: 'ItemsToShip',
  components: {
    ItemsToShipWrapper,
    FullHeightSdCardWrapper,
    TableWrapper,
  },
  setup() {
    const { state } = useStore();
    const order = computed(() => state.orders.order);

    const items = computed(() => {
      return state.orders.order.items || [];
    });

    const tableData = computed(() =>
      items.value.length
        ? items.value.map((item) => {
            const {
              item_id,
              name,
              sku,
              qty_ordered,
              qty_shipped,
              price,
              qty_invoiced,
              tax_amount,
              row_total,
              discount_amount,
              extension_attributes,
            } = item;
            let vin = extension_attributes?.gift_message?.message || '';
            console.log(vin, 'vin', name);
            return {
              key: item_id,
              product: vin ? (
                <div>
                  <div>
                    {name} - {sku}
                  </div>
                  <div>SKU: {sku}</div>
                  <div>Vehicle Details: {vin}</div>
                </div>
              ) : (
                <div>
                  <div>
                    {name} - {sku}
                  </div>
                  <div>SKU: {sku}</div>
                </div>
              ),
              price,
              qty: (
                <div>
                  <div>Ordered {qty_ordered}</div>
                  <div>Shipped {qty_shipped}</div>
                </div>
              ),
              qty_to_invoice: qty_invoiced,
              sub_total: qty_invoiced * price,
              tax_amount,
              discount_amount,
              row_total,
            };
          })
        : [],
    );
    return {
      order,
      tableColumns,
      tableData,
    };
  },
};
</script>
