<template>
  <InformationPageHeader>
    <sdButton v-if="enableCheckPickup" type="warning" @click="checkPickupStatus">Check Pickup Status</sdButton>
    <sdButton v-if="enableCreateMore && enableCreateInvoice" type="primary" @click="createInvoice"
      >Create Invoice</sdButton
    >
    <sdButton v-if="enableCreateMore && enableCreateShipment" type="primary" @click="createShipment"
      >Create Fulfilment</sdButton
    >
  </InformationPageHeader>
  <OrderInformation />
  <!-- <AddressInformation /> -->
  <PaymentMethod />
  <ItemsToShip />
</template>

<script>
import OrderInformation from './OrderInformation.vue';
// import AddressInformation from './AddressInformation.vue';
import PaymentMethod from './PaymentMethod.vue';
import ItemsToShip from './ItemsToShip.vue';
import { InformationPageHeader } from './style';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  components: {
    OrderInformation,
    // AddressInformation,
    PaymentMethod,
    InformationPageHeader,
    ItemsToShip,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();

    const { state, dispatch } = useStore();
    const order = computed(() => state.orders.order);

    const enableCreateMore = computed(() => {
      const bannedStatus = ['holded', 'closed', 'complete', 'canceled'];
      return !(bannedStatus.indexOf(order.value.status) >= 0);
    });

    const enableCreateShipment = computed(() => {
      return state.orders.order.items
        ? state.orders.order.items.some((item) => item.qty_ordered > item.qty_shipped)
        : false;
    });

    const enableCreateInvoice = computed(() => {
      return state.orders.order.items
        ? state.orders.order.items.some((item) => item.qty_ordered > item.qty_invoiced)
        : false;
    });

    const enableCheckPickup = computed(() => {
      return order.value.extension_attributes
        ? order.value.extension_attributes.shipping_assignments[0].shipping.method === 'collection_collection'
        : false;
    });

    const createShipment = () => {
      router.push({
        name: 'omni-order-shipment-view',
        params: { id: params.id, shipmentId: 'create' },
      });
    };

    const createInvoice = () => {
      router.push({
        name: 'omni-order-invoice-view-create',
        params: { id: params.id, invoiceId: 'create' },
      });
    };

    const checkPickupStatus = async () => {
      await dispatch('checkPickup', {
        orderIds: [order.value.entity_id],
      });
    };

    return {
      createShipment,
      createInvoice,
      enableCreateMore,
      enableCreateShipment,
      enableCreateInvoice,
      checkPickupStatus,
      enableCheckPickup,
    };
  },
};
</script>
